import React from "react"
import { useForm } from "react-hook-form"
import { navigate } from "gatsby-link"

import Banner from "../components/banner"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Button } from "../components/button"

function ContactPage() {
  const { register, handleSubmit, errors } = useForm()
  const onSubmit = async (data) => {
    if (data.checkf && data.checkf.length > 0) return

    const { email, name, subject, message } = data

    try {
      const res = await fetch("/.netlify/functions/submit-ticket", {
        method: "POST",
        body: JSON.stringify({
          email,
          name,
          subject,
          message,
          type: "general-enquiry",
        }),
      })

      if (res.ok) {
        const json = await res.json()

        if (json.data.error) {
          navigate(`/support/failure`)
        } else {
          navigate(`/support/success`)
        }
      }
    } catch (error) {
      // Debugging
      // console.log("error submitting request", error)
      navigate(`/support/failure`)
    }
  }

  return (
    <>
      <Seo title="Contact Us" />

      <Layout>
        <Banner label="Getting Help">Website &amp; Membership Support</Banner>

        <div className="container mx-auto px-3 py-6 lg:py-10">
          <p className="max-w-2xl text-xl mb-6">
            Thank you for visiting our website. If you have any questions,
            comments or feedback, please don't hesitate to reach out to us via
            this contact form.
          </p>

          <p className="max-w-2xl text-xl mb-6">
            We're always happy to help and will do our best to get back to you
            as soon as possible. To assist us in better understanding your
            inquiry, please fill in the required fields with as much information
            as possible.
          </p>

          <p className="max-w-2xl text-xl mb-6">
            Thank you for taking the time to contact us. We look forward to
            hearing from you!
          </p>

          <form onSubmit={handleSubmit(onSubmit)} className="max-w-2xl">
            <dl>
              <dt>
                <label className="font-semibold">Name</label>
              </dt>
              <dd className="mb-6">
                <input
                  {...register("name")}
                  className="w-full px-4 py-2 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                />
              </dd>

              <dt>
                <label className="font-semibold" htmlFor="form1705-email">
                  E-Mail Address
                </label>
              </dt>
              <dd className="mb-6">
                <input
                  {...register("email")}
                  type="email"
                  className="w-full px-4 py-2 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                />
              </dd>

              <dt>
                <label className="font-semibold" htmlFor="form1705-subject">
                  Subject
                </label>
              </dt>
              <dd className="mb-6">
                <input
                  {...register("subject")}
                  className="w-full px-4 py-2 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                />
              </dd>

              <dt>
                <label className="font-semibold" htmlFor="form1705-message">
                  Message
                </label>
              </dt>
              <dd className="mb-6">
                <textarea
                  {...register("message")}
                  className="w-full px-4 py-2 rounded-lg bg-gray-200 mt-2 border focus:border-blue-500 focus:bg-white focus:outline-none"
                />
              </dd>
            </dl>

            <Button size="medium" type="submit">
              Submit
            </Button>

            <input {...register("checkf")} style={{ display: "none" }} />
          </form>
        </div>
      </Layout>
    </>
  )
}

export default ContactPage
